

























































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'

@Component
export default class List extends Vue {
      pagerHeader: any = {
        title: "客户端配置(请求字段clientConfig)",
        desc: "为客户端配置分钟数或者费用，客户端从这儿获取配置(充电宝免费时长：theFreeTime、充电宝套费用配置：chargeTreasureCharge、充电线费用配置：chargeLineCost、充电宝时长配置：chargingTime、充电线时长配置：chargeLineTime)",
        breadcrumb: ["配置", "客户端配置"]
    };
    activeName:String= 'theFreeTime';
    tableData: any= {
        name:'clientConfig', 
        value:{}
    }
    // 删除
    handleDelete(index:any){
        console.log(index);
         let activeName:any=this.activeName
         if(this.tableData.value[activeName].length==1){
             alert('不能删除最后一项')
             return;
         }else{
             this.tableData.value[activeName].splice(index,1)
         }
        
        this.saveData()
    }
    // 插入
    interposition(index:number){
        let that=this
         var re2 = /[\u4e00-\u9fa5]/g   //匹配文字
        let name:any=that.activeName
          let str;
          let num
          if(name=='theFreeTime'||name=='chargingTime'||name=="chargeLineTime"){
              if(that.tableData.value[name][index].name.match(re2).join('')=="分钟"){
                 num=parseFloat(that.tableData.value[name][index].name)-5;
                 if(num<1){
                     alert('不能小于一分钟')
                     return
                 }else{
                     str=num+'分钟'
                 }
                
              }else{
                  if(parseFloat(that.tableData.value[name][index].name)-1<1){
                    num=55;
                    str=55+'分钟'
                  }else{
                      num=parseFloat(that.tableData.value[name][index].value)-60;
                        str=parseFloat(that.tableData.value[name][index].name)-1+'小时'
                  }
              }
            }else {
                if(that.tableData.value[name][index].name.match(re2).join('')=="角"){
                     num=parseFloat(that.tableData.value[name][index].name)-0.5;
                    if(num*0.1<0.5){
                        alert('不能小于5角')
                        return;
                    }else{
                        num=parseFloat(that.tableData.value[name][index].name)-0.5;
                        str=num+'角'
                    }
                }else{
                     num=parseFloat(that.tableData.value[name][index].name)-1;
                     if(num<0.1){
                        num=parseFloat(that.tableData.value[name][index].name)-0.5;
                        str=num+'角'
                    }else{
                        num=parseFloat(that.tableData.value[name][index].name)-1;
                         str=num+'元'
                    }
                }
            }
        that.tableData.value[name].splice(index,0,{name:str,value:num})
    }
    // 保存修改
    async saveData(){ 
        var re1 = /(\d{1,3})+(?:\.\d+)?/g  //匹配数字
        let activeName:any=this.activeName
        let isTrue=true
        for(var i in this.tableData.value) {
                this.tableData.value[i].forEach((element:any) => {
                         if(element.name==""||parseInt(element.name)<0){
                            isTrue=false
                        } 
                });
            }
            
        if(!isTrue){
            // alert('值不能为空/不能为负值')
            this.$notify({
                title:'值不能为空/不能为负值',
                message: '',
                type: "warning"
            });
        }else{
            console.log(this.tableData);
            
            try {
                let res = await this.$ajax.post(
                "/api/services/app/Configuration/ChangeClientConfig",
                this.tableData
                );
                if(res.data.success){
                    this.$notify({
                        title:'成功',
                        message: '',
                        type: "success"
                    });
                }
            } catch (error) {
                    console.log(error);
                } 
        }
    }
    created() {
        this.gainData()
    }
    // 监听输入框输入
    inputblur(event:any,index:any){
        let name:any=this.activeName
         var re1 = /(\d{1,3})+(?:\.\d+)?/g  //匹配数组
        var re2 = /[\u4e00-\u9fa5]/g   //匹配文字
        if(name=="theFreeTime"||name=="chargingTime"||name=="chargeLineTime"){
            let wen
            if(event.target.value.match(re2)){
                if(event.target.value.match(re2).join('')=='分钟'){
                wen=parseFloat(event.target.value.match(re1).join(''))
                }else if(event.target.value.match(re2).join('')=='小时'){
                    wen=parseFloat(event.target.value.match(re1).join(''))*60
                }
            }else{
                    this.tableData.value[name][index].name=parseFloat(event.target.value.match(re1).join(''))+'分钟'
                    wen=parseFloat(event.target.value.match(re1).join(''))
                }

            this.tableData.value[name][index].value=wen
        }else{
            let yuan
            if(event.target.value.match(re2)){
                if(event.target.value.match(re2).join('')=="元"){
                 yuan=parseFloat(event.target.value.match(re1).join(''))
                }else if(event.target.value.match(re2).join('')=="角"){
                 yuan=parseFloat(event.target.value.match(re1).join(''))*0.1
                }
            }else{
                 this.tableData.value[name][index].name=parseFloat(event.target.value.match(re1).join(''))+'元'
                 yuan=parseFloat(event.target.value.match(re1).join(''))
            }
            this.tableData.value[name][index].value=yuan
        }
       
        
    }
    // 添加数据
    async addData(){    
        let name:any=this.activeName
        if(this.tableData.value.hasOwnProperty(name)){
            let lg=this.tableData.value[name].length
            var re2 = /[\u4e00-\u9fa5]/g   //匹配文字
            const that=this
            let str;
            let num;
            if(lg==0){
               if(name=="theFreeTime"||name=="chargingTime"||name=="chargeLineTime"){
                    this.tableData.value[name]=[{
                    name:'5分钟',
                    value:'5'
                }]
             }else{
                this.tableData.value[name]=[{
                    name:'1元',
                    value:'1'
                }]
             }
            }else{
                if(name=='theFreeTime'||name=='chargingTime'||name=="chargeLineTime"){
                    if(that.tableData.value[name][lg-1].name.match(re2).join('')=="分钟"){
                        num=parseInt(that.tableData.value[name][lg-1].name)+5;
                        str=num+'分钟'
                    }else{
                        num=parseInt(that.tableData.value[name][lg-1].value)+60;
                        str=parseInt(that.tableData.value[name][lg-1].name)+1+'小时'
                    }
                }else {
                    num=parseInt(this.tableData.value[name][lg-1].name)+1;
                    str=num+'元'
                }
                this.tableData.value[name].push({
                    name:str,
                    value:num
                })  
            }

        }else {
             if(name=="theFreeTime"||name=="chargingTime"||name=="chargeLineTime"){
                    this.tableData.value[name]=[{
                    name:'5分钟',
                    value:'5'
                }]
             }else{
                this.tableData.value[name]=[{
                    name:'1元',
                    value:'1'
                }]
             }
           
        }
        
 

    }
    // 获取数据
    async gainData(){
        let that=this
        try {
            let res =await this.$ajax.get('/api/services/app/Configuration/GetClientConfig',{
                params:{name:'clientConfig'}
            })
            if(res.data.success){
                that.tableData.value =res.data.result
            }
        } catch (error) {
            console.log(error);
        }
    }
}
